
import {CombinedVueInstance} from "vue/types/vue";

export default {
  name: "menu-button",
  computed: {
    active: function (): any {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return !vm.$root.menuClosed
    }
  },
  methods: {
    "toggleMenu": function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      vm.$root.menuClosed = !vm.$root.menuClosed
      // Hack scrolling
      document.querySelector('body').classList.toggle('overflow-hidden', vm.active);
    }
  },
}
