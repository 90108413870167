var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "border p-3 opacity-100 cursor-pointer",
      on: { click: _vm.toggleItem },
    },
    [
      _c(
        "a",
        {
          staticClass: "uppercase font-bold",
          attrs: { href: _vm.hasSublist ? "#" : _vm.url },
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _vm.hasSublist
        ? _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.open,
                  expression: "open",
                },
              ],
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("a", { attrs: { href: _vm.url } }, [
                _vm._v(_vm._s(_vm.subTitle)),
              ]),
              _vm._v(" "),
              _vm._t("sublist"),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }