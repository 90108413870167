
    export default {
        name: 'collapsible',
        data: function (): any {
            return {
                open: false
            }
        },
        methods: {
            toggleOpen: function () {
                const vm: any = this;
                vm.open = !vm.open
            }
        }
    }
