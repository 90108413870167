var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "m-2 mt-4" }, [
    _vm.active
      ? _c(
          "a",
          {
            staticClass:
              "button uppercase w-fit-content border border-solid border-tundora p-2 cursor-pointer",
            attrs: { href: _vm.courseHref, target: _vm.courseTarget },
            on: { click: _vm.openEnrolment },
          },
          [_vm._t("default")],
          2
        )
      : _c(
          "div",
          {
            staticClass:
              "button uppercase w-fit-content border border-solid border-tundora p-2",
          },
          [_vm._t("default")],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }