
import {CombinedVueInstance} from "vue/types/vue";
import Axios, {AxiosError, AxiosResponse} from "axios";
import {validationMixin} from 'vuelidate'
import {
  email,
  helpers,
  integer,
  maxLength,
  minLength,
  numeric,
  or,
  required,
  requiredIf,
  requiredUnless
} from "vuelidate/lib/validators";
import {defineComponent} from "vue";
import parse from "date-fns/parse"

const germanDate = helpers.regex('germanDate', /^[0-3][0-9][/.][0-3][0-9][/.](?:[0-9][0-9])?[0-9][0-9]$/)
const isEmpty = (value: string) => helpers.len(value) === 0

const emptyOrGermanDate = or(germanDate, isEmpty)

const inThePast = (value: string) => isEmpty(value) || parse(value, "dd.MM.yyyy", new Date()) < new Date()

type SubmitStatus = "PENDING" | "OK" | "ERROR" | "INVALID";

export default defineComponent({
  name: "course-form",
  data: function () {
    return {
      agb: false,
      vorname: '',
      nachname: '',
      adresse: '',
      plz: '',
      city: '',
      phone: '',
      mobile: '',
      email: '',
      birthday: '',
      dueDate: '',
      birthdayChild: '',
      insurance: '',
      insuredNumber: '',
      insurerNumber: '',
      insuranceType: '',
      numChildren: '',
      doctor: '',
      occupation: '',
      referer: '',
      experience: '',
      submitStatus: null as SubmitStatus,
      message: '',
    }
  },
  computed: {
    isSubmitted: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return vm.submitStatus === 'OK';
    },
    isSubmitDisabled: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return vm.submitStatus == 'PENDING' || !vm.agb;
    },
    isPetraCourse: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return !vm.$root.course.schneider && !vm.$root.course.haugschopf;
    },
    isMiyaCourse: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return vm.$root.course.miya;
    },
    isSchneiderCourse: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return vm.isMiyaCourse && vm.$root.course.schneider;
    },
    isHeidiCourse: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return vm.$root.course.haugschopf;
    }
  },
  methods: {
    closeForm: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      vm.$root.courseFormClosed = true;
    },
    sendRequest: function (formData: FormData) {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      const {id, instanceId} = vm.$root.course;
      return Axios.post(`/kursanmeldung/${id}/${instanceId}`,
          formData,
          {headers: {'Content-Type': 'multipart/form-data'}}
      )
    },
    resetInsurance: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      vm.$v.insuredNumber.$reset()
      vm.$v.insurerNumber.$reset()
    },
    prepareData: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      const formData = new FormData();
      formData.append(vm.$root.course.tokenName, vm.$root.course.token);
      formData.append("courseLeader", vm.$root.course.leader);
      for (const formKey in vm.$data) {
        if (vm.$data.hasOwnProperty(formKey)) {
          formData.append(formKey, vm.$data[formKey]);
        }
      }
      return formData;
    },
    submitForm: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      vm.$v.$touch()
      if (vm.$v.$invalid) {
        vm.submitStatus = 'INVALID'
      } else {
        vm.submitStatus = 'PENDING'
        vm.sendRequest(vm.prepareData()).then((res: AxiosResponse) => {
          vm.submitStatus = res.status === 200 && !res.data.error ? 'OK' : 'ERROR';
          vm.$data.message = res.data.message;
        }).catch((e: AxiosError<{error: boolean, message: string}>) => {
          if (e.response?.data?.message !== undefined) {
            vm.$data.message = e.response.data.message
          }
          console.error(e);
          vm.submitStatus = 'ERROR'
        })
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    vorname: {required, minLength: minLength(2)},
    nachname: {required, minLength: minLength(2)},
    adresse: {required},
    plz: {required, integer, minLength: minLength(5), maxLength: maxLength(5)},
    city: {required, minLength: minLength(2)},
    phone: {required, minLength: minLength(5)},
    mobile: {required: requiredIf('isSchneiderCourse'), minLength: minLength(5)},
    email: {required, email},
    birthday: {required, germanDate},
    dueDate: {required, germanDate},
    birthdayChild: { emptyOrGermanDate, inThePast},
    doctor: {},
    insurance: {},
    numChildren: {required: requiredUnless('isSchneiderCourse'), numeric},
    occupation: {},
    referer: {},
    experience: {},
    insuranceType: {required: requiredIf('isSchneiderCourse')},
    insurerNumber: {
      required: requiredIf((vm) => vm.isSchneiderCourse && vm.insuranceType === 'G'),
      minLength: minLength(9), maxLength: maxLength(9), numeric
    },
    insuredNumber: {required: requiredIf((vm) => vm.isSchneiderCourse && vm.insuranceType === 'G')},
  }
})
