/***
 * Dependencies
 */
import Vue from 'vue';
import './src/sass/main.scss'
import AccordionItem from './src/components/accordion-item.vue';
import MenuButton from './src/components/menu-button.vue';
import MenuItem from './src/components/menu-item.vue';
import Collapsible from './src/components/collapsible.vue';
import VuePlyr from './src/components/plyr.vue';
import CourseForm from './src/components/course-form.vue';
import CourseButton from './src/components/course-button.vue';
import VueSmoothScroll from 'vue2-smooth-scroll'

window.addEventListener("load", async () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/service-worker.js")
            .then(
                () => console.log('worker registered'),
                e => console.error('worker could not be registered', e),
            );
        navigator.serviceWorker.ready
            .then(
                () => console.log('worker activated'),
                e => console.error('worker could not be activated', e),
            )
    } else {
        console.warn('Service worker is not yet supported')
    }
});

Vue.use(VueSmoothScroll)

const vue = new Vue({
    el: '#app',
    components: {
        'accordion-item': AccordionItem,
        'menu-button': MenuButton,
        'menu-item': MenuItem,
        'collapsible': Collapsible,
        'vue-plyr': VuePlyr,
        'course-form': CourseForm,
        'course-button': CourseButton,
    },
    data: {
        menuClosed: true,
        courseFormClosed: true,
        course: {
            name: "",
            day: "",
            id: null,
            instanceId: null,
            token: "",
            tokenName: "",
        },
    }
});

function scrollTo(target: string, element: HTMLElement) {
    element && vue.$smoothScroll({
        scrollTo: element,
        duration: 1000,
        updateHistory: true,
        hash: `#${target}`,
        offset: -document.querySelector('header').offsetHeight
    });
}
function navigateToHash(hash: string) {
    // Scroll to elements identified with data-target instead of IDs (standard HTML anchors)
    const target = hash.substr(hash.indexOf('#') + 1);
    const element = document.querySelector(`[data-target="${target}"]`);
    scrollTo(target, element as HTMLElement)
}

if (window.location.hash) {
    // wait until page has loaded
    setTimeout(() => navigateToHash(window.location.hash), 500)
}

window.onhashchange = (event: HashChangeEvent) => navigateToHash(event.newURL)
