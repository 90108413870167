
import {CombinedVueInstance} from "vue/types/vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "course-button",
  props: {
    active: Boolean,
    title: String,
    day: String,
    course: String,
    instance: String,
    token: String,
    tokenName: String,
    agb: String,
    leader: String,
    miya: Boolean,
    zinsler: Boolean,
    schneider: Boolean,
    haugschopf: Boolean,
  },
  computed: {
    courseHref: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return vm.zinsler ? 'https://www.christine-zinsler.de/#kurse' : '#'
    },
    courseTarget: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return vm.zinsler ? '_blank' : '_self'
    }
  },
  methods: {
    openEnrolment: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return vm.zinsler ? false : vm.openForm();
    },
    openForm: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      vm.$root.course.id = vm.course;
      vm.$root.course.instanceId = vm.instance;
      vm.$root.course.name = vm.title;
      vm.$root.course.day = vm.day;
      vm.$root.course.leader = vm.leader;
      vm.$root.course.token = vm.token;
      vm.$root.course.tokenName = vm.tokenName;
      vm.$root.course.agb = vm.agb;
      vm.$root.course.miya = vm.miya;
      vm.$root.course.schneider = vm.schneider;
      vm.$root.course.haugschopf = vm.haugschopf;
      vm.$root.courseFormClosed = false;
      window.scroll(0, 0);
    },
  },
})
