var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("button", {
    staticClass: "hamburger-icon m-2",
    class: { "is-active": _vm.active },
    attrs: { "aria-label": "Menü" },
    on: {
      click: function ($event) {
        return _vm.toggleMenu()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }