var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative pt-4" }, [
    _c(
      "div",
      {
        staticClass: "flex flex-row justify-around content-start",
        class: { "bg-theme": _vm.themed },
      },
      [
        _c("div", { staticClass: "flex-grow" }, [_vm._t("header")], 2),
        _vm._v(" "),
        _c("img", {
          staticClass: "w-6 h-8 block mx-4 focus:outline-none cursor-pointer",
          class: { "my-4": _vm.themed },
          attrs: {
            alt: "arrow",
            src: _vm.themed
              ? "/images/arrow_white.svg"
              : "/images/arrow_grey.svg",
          },
          on: { click: _vm.toggleOpen },
        }),
      ]
    ),
    _vm._v(" "),
    _vm.open ? _c("div", [_vm._t("default")], 2) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }