
import {CombinedVueInstance} from "vue/types/vue";

export default {
  name: "menu-item",
  props: ['title', 'url'],
  data: function () {
    return {
      open: false
    }
  },
  computed: {
    subTitle: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      if (vm.title.toLowerCase().includes("praxis")) {
        return "Über uns";
      } else if (vm.title.toLowerCase().includes("kurse") || vm.title.toLowerCase().includes("behandlungen")) {
        return "Übersicht";
      } else {
        return vm.title;
      }
    },
    hasSublist: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      return !!vm.$slots['sublist'];
    }
  },
  methods: {
    toggleItem: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      vm.open = !vm.open;
    },
    closeMenu: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      vm.$root.menuClosed = true;
    }
  }
}
