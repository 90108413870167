
import {CombinedVueInstance} from "vue/types/vue";

export default {
  name: 'accordion-item',
  props: {
    themed: Boolean,
    trackId: String,
  },
  data: function (): any {
    return {
      open: false
    }
  },
  methods: {
    toggleOpen: function () {
      const vm: any = this;
      vm.open = !vm.open
      if (vm.trackId && vm.open) {
        vm.track()
      }
    },
    track: function () {
      const vm: CombinedVueInstance<any, any, any, any, any> = this;
      const formData = new URLSearchParams()
      formData.set("pid", vm.trackId)
      fetch(location.pathname.replace(/\/?$/, '/') + "phcv1", {
            method: "post",
        body: formData,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-Requested-With': 'XMLHttpRequest'
            }
          }
      )
    }
  }
}
