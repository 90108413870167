var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      staticClass: "w-full min-h-screen bg-athens small-font z-30 relative",
      attrs: { id: "courseForm" },
    },
    [
      _c(
        "div",
        { staticClass: "z-20 absolute top-0 right-0 mt-2 lg:mt-5 mr-5" },
        [
          _c("button", {
            staticClass: "hamburger-icon m-2 is-active",
            on: {
              click: function ($event) {
                return _vm.closeForm()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "container mx-auto px-2 md:px-4 lg:px-0 pt-8 lg:pt-16 pb-10 lg:pb-20",
        },
        [
          _c("h2", [_vm._v("Kursanmeldung")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "my-2", attrs: { hidden: !_vm.isSubmitted } },
            [
              _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$root.course.name))])]),
              _vm._v(" "),
              _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$root.course.day))])]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v("Kursleitung: " + _vm._s(_vm.$root.course.leader)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "border border-tundora px-4 py-1 my-4 uppercase w-fit-content",
                },
                [_vm._v("Gesendet")]
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm.message ? _c("div", [_vm._v(_vm._s(_vm.message))]) : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { hidden: _vm.isSubmitted },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                attrs: { type: "hidden", name: _vm.$root.course.tokenName },
                domProps: { value: _vm.$root.course.token },
              }),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  attrs: {
                    id: "courseName",
                    disabled: "disabled",
                    name: "courseName",
                    placeholder: "Kurs",
                    type: "text",
                  },
                  domProps: { value: _vm.$root.course.name },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  attrs: {
                    id: "courseDay",
                    disabled: "disabled",
                    name: "courseDay",
                    placeholder: "Termin",
                    type: "text",
                  },
                  domProps: { value: _vm.$root.course.day },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  attrs: {
                    id: "courseLeader",
                    disabled: "disabled",
                    name: "courseLeader",
                    placeholder: "Kursleiter",
                    type: "text",
                  },
                  domProps: { value: _vm.$root.course.leader },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.vorname,
                      expression: "vorname",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      this.submitStatus && _vm.$v.vorname.$invalid,
                  },
                  attrs: {
                    id: "vorname",
                    name: "vorname",
                    placeholder: "Vorname *",
                    type: "text",
                  },
                  domProps: { value: _vm.vorname },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.vorname = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.vorname.required
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Der Vorname muss\n          angegeben werden\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.nachname,
                      expression: "nachname",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      this.submitStatus && _vm.$v.nachname.$invalid,
                  },
                  attrs: {
                    id: "nachname",
                    name: "nachname",
                    placeholder: "Nachname *",
                    type: "text",
                  },
                  domProps: { value: _vm.nachname },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.nachname = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.nachname.required
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Der Nachname muss\n          angegeben werden\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.adresse,
                      expression: "adresse",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      this.submitStatus && _vm.$v.adresse.$invalid,
                  },
                  attrs: {
                    id: "adresse",
                    name: "adresse",
                    placeholder: "Straße, Nr. *",
                    type: "text",
                  },
                  domProps: { value: _vm.adresse },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.adresse = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.adresse.required
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "\n          Straße und Hausnummer müssen angegeben werden\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.plz,
                      expression: "plz",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      (_vm.plz || this.submitStatus) && _vm.$v.plz.$invalid,
                  },
                  attrs: {
                    id: "plz",
                    name: "plz",
                    placeholder: "Postleitzahl *",
                    type: "text",
                  },
                  domProps: { value: _vm.plz },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.plz = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.plz.required
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Die PLZ muss angegeben\n          werden\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.plz && !_vm.$v.plz.integer
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Die PLZ darf nur Zahlen\n          enthalten\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.plz && !_vm.$v.plz.minLength
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v("Die PLZ ist zu kurz"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.plz && !_vm.$v.plz.maxLength
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v("Die PLZ ist zu lang"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.city,
                      expression: "city",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      this.submitStatus && _vm.$v.city.$invalid,
                  },
                  attrs: {
                    id: "city",
                    name: "city",
                    placeholder: "Ort *",
                    type: "text",
                  },
                  domProps: { value: _vm.city },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.city = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.city.required
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Der Ort muss angegeben\n          werden\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.phone,
                      expression: "phone",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      this.submitStatus && _vm.$v.phone.$invalid,
                  },
                  attrs: {
                    id: "phone",
                    name: "phone",
                    placeholder: "Telefon *",
                    type: "text",
                  },
                  domProps: { value: _vm.phone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.phone = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && _vm.$v.phone.$invalid
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Die Telefonnummer muss\n          angegeben werden (mind. 5 Zeichen)\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isSchneiderCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.mobile,
                          expression: "mobile",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      class: {
                        "border-redorange":
                          (_vm.mobile || this.submitStatus) &&
                          _vm.$v.mobile.$invalid,
                      },
                      attrs: {
                        id: "mobile",
                        name: "mobile",
                        placeholder: "Handy *",
                        type: "text",
                      },
                      domProps: { value: _vm.mobile },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.mobile = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.submitStatus === "INVALID" && !_vm.$v.mobile.required
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "Die Handynummer muss\n          angegeben werden\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mobile && !_vm.$v.mobile.minLength
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "Die Handynummer muss mindestens 5\n          Zeichen lang sein\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      (_vm.email || this.submitStatus) && _vm.$v.email.$invalid,
                  },
                  attrs: {
                    id: "email",
                    name: "email",
                    placeholder: "E-Mail *",
                    type: "text",
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.email.required
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Der E-Mail muss\n          angegeben\n          werden\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.email && !_vm.$v.email.email
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Die E-Mail hat nicht das richtige Format\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.birthday,
                      expression: "birthday",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      (_vm.birthday || this.submitStatus) &&
                      _vm.$v.birthday.$invalid,
                  },
                  attrs: {
                    id: "birthday",
                    name: "birthday",
                    placeholder: "Geburtstag (TT.MM.JJJJ) *",
                    type: "text",
                  },
                  domProps: { value: _vm.birthday },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.birthday = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.birthday.required
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v("Der Geburtstag muss angegeben werden"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.birthday && !_vm.$v.birthday.germanDate
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "\n          Der Geburtstag muss im Format TT.MM.JJJJ angegeben werden\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dueDate,
                      expression: "dueDate",
                    },
                  ],
                  staticClass: "block mt-2 p-2 w-full border-2 border-solid",
                  class: {
                    "border-redorange":
                      this.submitStatus && _vm.$v.dueDate.$invalid,
                  },
                  attrs: {
                    id: "dueDate",
                    name: "dueDate",
                    placeholder: "Errechneter Entbindungstermin (TT.MM.JJJJ) *",
                    type: "text",
                  },
                  domProps: { value: _vm.dueDate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.dueDate = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.isHeidiCourse
                  ? _c("small", { staticClass: "pa-2 mb-2 text-center" }, [
                      _vm._v(
                        "Falls der Kurs nichts mit Schwangerschaft und Babyjahr zu tun hat - bitte einfach das aktuelle Datum eintragen."
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.dueDate.required
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v("Der Entbindungstermin muss angegeben werden"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.$v.dueDate.germanDate
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "\n          Der Entbindungstermin muss im Format TT.MM.JJJJ angegeben werden\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isSchneiderCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.birthdayChild,
                          expression: "birthdayChild",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      class: {
                        "border-redorange":
                          this.submitStatus && _vm.$v.birthdayChild.$invalid,
                      },
                      attrs: {
                        id: "birthdayChild",
                        name: "birthdayChild",
                        placeholder: "Geburtstag Kind (TT.MM.JJJJ)",
                        type: "text",
                      },
                      domProps: { value: _vm.birthdayChild },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.birthdayChild = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.submitStatus === "INVALID" &&
                    !_vm.$v.birthdayChild.emptyOrGermanDate
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "\n          Der Geburtstag des Kindes muss im Format TT.MM.JJJJ angegeben werden\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.submitStatus === "INVALID" &&
                    !_vm.$v.birthdayChild.inThePast
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "\n          Der Geburtstag des Kindes muss in der Vergangenheit liegen\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isPetraCourse || _vm.isHeidiCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.numChildren,
                          expression: "numChildren",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      class: {
                        "border-redorange":
                          (_vm.numChildren || this.submitStatus) &&
                          _vm.$v.numChildren.$invalid,
                      },
                      attrs: {
                        id: "numChildren",
                        name: "numChildren",
                        placeholder: "Anzahl ihrer Kinder vor der Geburt*",
                        type: "text",
                      },
                      domProps: { value: _vm.numChildren },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.numChildren = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.submitStatus === "INVALID" &&
                    !_vm.$v.numChildren.required
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "\n          Die Anzahl der Kinder muss angegeben werden\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.numChildren && !_vm.$v.numChildren.numeric
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "Die Anzahl der Kinder muss eine\n          Zahl sein\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isPetraCourse || _vm.isHeidiCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.occupation,
                          expression: "occupation",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      attrs: {
                        id: "occupation",
                        name: "occupation",
                        placeholder: "Beruf",
                        type: "text",
                      },
                      domProps: { value: _vm.occupation },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.occupation = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isPetraCourse || _vm.isHeidiCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.doctor,
                          expression: "doctor",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      attrs: {
                        id: "doctor",
                        name: "doctor",
                        placeholder: "Behandelnder Arzt",
                        type: "text",
                      },
                      domProps: { value: _vm.doctor },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.doctor = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isPetraCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.insurance,
                          expression: "insurance",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      attrs: {
                        id: "insurance",
                        name: "insurance",
                        placeholder: "Krankenversicherung",
                        type: "text",
                      },
                      domProps: { value: _vm.insurance },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.insurance = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isSchneiderCourse
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "border-2 border-solid",
                        class: {
                          "border-redorange":
                            this.submitStatus && _vm.$v.insuranceType.$invalid,
                        },
                      },
                      [
                        _c("label", { attrs: { for: "insuranceType" } }, [
                          _c("span", { staticClass: "p-2" }, [
                            _vm._v("Art der Krankenversicherung *"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.insuranceType,
                                  expression: "insuranceType",
                                },
                              ],
                              staticClass: "w-56",
                              attrs: {
                                id: "insuranceType",
                                name: "insuranceType",
                                type: "text",
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.insuranceType = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.resetInsurance,
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "G" } }, [
                                _vm._v("Gesetzlich"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "P" } }, [
                                _vm._v("Privat"),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.submitStatus === "INVALID" &&
                    _vm.$v.insuranceType.$invalid
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "Die Art der\n          Versicherung muss angegeben\n          werden\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.insuranceType === "G" && _vm.isSchneiderCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.insuredNumber,
                          expression: "insuredNumber",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      class: {
                        "border-redorange":
                          this.submitStatus && _vm.$v.insuredNumber.$invalid,
                      },
                      attrs: {
                        id: "insuredNumber",
                        name: "insuredNumber",
                        placeholder:
                          "Ihre Versichertennummer (siehe Gesundheitskarte)*",
                        type: "text",
                      },
                      domProps: { value: _vm.insuredNumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.insuredNumber = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.submitStatus === "INVALID" &&
                    _vm.$v.insuredNumber.$invalid
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "\n          Die Versichertennummer muss angegeben werden\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isSchneiderCourse && _vm.insuranceType === "G"
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.insurerNumber,
                          expression: "insurerNumber",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      class: {
                        "border-redorange":
                          this.submitStatus && _vm.$v.insurerNumber.$invalid,
                      },
                      attrs: {
                        id: "insurerNumber",
                        name: "insurerNumber",
                        placeholder:
                          "Nummer der Krankenkasse (siehe Gesundheitskarte) *",
                        type: "text",
                      },
                      domProps: { value: _vm.insurerNumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.insurerNumber = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.submitStatus === "INVALID" &&
                    _vm.$v.insurerNumber.$invalid
                      ? _c("div", { staticClass: "text-redorange mb-2" }, [
                          _vm._v(
                            "Die Nummer des\n          gesetzlichen Versicherers muss 9 Ziffern beinhalten\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isPetraCourse || _vm.isHeidiCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.experience,
                          expression: "experience",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      attrs: {
                        id: "experience",
                        name: "experience",
                        placeholder:
                          "Kurse, die sie schon bei uns gemacht haben",
                        type: "text",
                      },
                      domProps: { value: _vm.experience },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.experience = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isPetraCourse || _vm.isHeidiCourse
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.referer,
                          expression: "referer",
                        },
                      ],
                      staticClass:
                        "block mt-2 p-2 w-full border-2 border-solid",
                      attrs: {
                        id: "referer",
                        name: "referer",
                        placeholder:
                          "Wie sind Sie auf die Praxis aufmerksam geworden",
                        type: "text",
                      },
                      domProps: { value: _vm.referer },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.referer = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "my-4" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agb,
                        expression: "agb",
                      },
                    ],
                    attrs: { id: "agb", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.agb)
                        ? _vm._i(_vm.agb, null) > -1
                        : _vm.agb,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.agb,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.agb = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.agb = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.agb = $$c
                        }
                      },
                    },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("Ich akzeptiere die "),
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.$root.course.agb, target: "_blank" },
                      },
                      [_vm._v("Allgemeinen Geschäftsbedingungen")]
                    ),
                    _vm._v(" dieses Kurses, inkl. Datenschutz betreffend"),
                  ]),
                ]),
                _vm._v(" "),
                _vm.submitStatus === "INVALID" && !_vm.agb
                  ? _c("div", { staticClass: "text-redorange mb-2" }, [
                      _vm._v(
                        "Bitte akzeptieren Sie die\n          Allgemeinen Geschäftsbedingungen\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.submitStatus === "ERROR"
                ? _c("div", { staticClass: "text-redorange mb-2" }, [
                    _vm.message
                      ? _c("div", [_vm._v(_vm._s(_vm.message))])
                      : _c("div", [
                          _vm._v(
                            "Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder melden sich bei webmaster@praxis-kidler19.de"
                          ),
                        ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", [_vm._v("* Pflichtfelder")]),
              _vm._v(" "),
              _c("div", { staticClass: "my-4" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "border border-tundora px-4 py-1 mb-2 uppercase w-fit-content",
                    class: { "text-lightgray": _vm.isSubmitDisabled },
                    attrs: {
                      id: "submit",
                      disabled: _vm.isSubmitDisabled,
                      name: "submit",
                      type: "submit",
                      value: "Buchung senden",
                    },
                  },
                  [
                    _c("span", { staticClass: "ui-button-text" }, [
                      _vm._v("Buchung senden"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "italic small-font" }, [
                _vm._v(
                  "Wir verwenden Ihre Daten\n        ausschließlich praxisintern. Sie werden weder für andere Zwecke weitergegeben noch verkauft. Ihre Daten werden\n        verschlüsselt übertragen.\n      "
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "my-2" }, [
      _c("strong", [_vm._v("Vielen Dank für die Anmeldung!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "italic small-font my-2" }, [
      _c("p", [
        _vm._v(
          'Es kann vorkommen, dass ein Kurs für einen kurzen Zeitraum "freie Plätze"\n      zeigt, obwohl gerade der letzte freie Platz vergeben wurde. Bitte beachten Sie, dass der Platz für Sie erst mit\n      Erhalt der\n      Zusage von uns sicher ist.'
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }